<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.PaymentAdd) ||
            loginUserRole == 'Admin'
        "
        class="vx-col w-full mb-base"
      >
        <PaymentModeForm />
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.PaymentAdd
              ) ||
                permissionPageAccessArry.includes(
                  PermissionVariables.PaymentView
                ) ||
                loginUserRole == 'Admin'
            "
            label="Payment Mode List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <PaymentModeList />
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentModeForm from "@/views/apps/masters/paymentMode/PaymentModeForm.vue";
import PaymentModeList from "@/views/apps/masters/paymentMode/PaymentModeList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    PaymentModeForm,
    PaymentModeList,
  },

  data() {
    return {
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      loginUserRole: window.localStorage.getItem("UserRole"),
      PermissionVariables: PermissionVariables,
    };
  },
};
</script>
