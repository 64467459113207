<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Add Payment Mode">
          <form>
            <div class="vx-row">
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
                  >
                    <h6 class="mb-0">Payment Mode Name*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Payment Mode Name"
                        v-model="form.payment_mode_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Payment Mode Name')"
                        >{{ errors.first("Payment Mode Name") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
                    <h6 class="mb-0">Logo*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vue-dropzone
                      @vdropzone-files-added="vdropzoneFilesAdded"
                      @vdropzone-success="vdropzoneSuccess"
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                    ></vue-dropzone>
                    <vs-button
                      v-if="imageUpload.deleteButtonStatus"
                      type="filled"
                      @click.prevent="clearImage()"
                      class="feather icon-trash mt-2"
                    ></vs-button>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
                    <h6 class="mb-0">Status*</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-switch v-model="switch1" />
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="form.payment_mode_name.length <= 0"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3 mb-3 w-full sm:w-auto"
                  >Submit</vs-button
                >

                <vs-button
                  color="warning"
                  type="border"
                  class="mb-3 w-full sm:w-auto"
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import constant from "@/helper/constant";

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import { BASEURL } from "@/config/index.js";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      submitStatus: true,
      switch1: true,
      form: {
        payment_mode_name: "",
        payment_mode_icon: "",
        payment_mode_isActive: 1,
      },
      dropzoneOptions: {
        url:
          BASEURL +
          "/v2/multipleUploads?organization_id=" +
          this.organization_id,
        acceptedFiles: "image/*",
        ...constant.DEFAULT_IMAGE,
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
      },
      imageUpload: {
        image_url: "",
        deleteButtonStatus: false,
      },
    };
  },
  beforeMount() {
    this.dropzoneOptions["url"] =
      BASEURL + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptions["headers"]["authorization"] = this.access_token;
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        delete this.form["payment_mode_icon"];
        let len = Object.keys(this.form).length;
        console.log(len, "iuy");
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }

        if (!this.form.payment_mode_icon) {
          this.form["payment_mode_icon"] = "";
        }
      },
      deep: true,
    },
  },

  methods: {
    clearImage: function() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.imageUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzone.enable();
      this.form.payment_mode_icon = "";
    },
    vdropzoneFilesAdded: function() {
      console.log("vdropzoneFilesAdded");
    },
    vdropzoneSuccess: function(file, response) {
      this.form.payment_mode_icon = response.data.image_path;
      this.imageUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzone.disable();
    },
    vdropzoneError: function(file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            payment_mode_id: "",
            payment_mode_name: this.form.payment_mode_name,
            payment_mode_icon: this.form.payment_mode_icon,
            payment_mode_isActive: this.switch1 ? "1" : "2",
          };

          MasterService.addPaymentMode(payload)
            .then((response) => {
              this.submitStatus = false;

              const { data } = response;
              this.$vs.loading.close();

              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.clearForm();

                setTimeout(() => {
                  eventBus.$emit("conrefreshTablePaymentModeType", data);
                }, 1);
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              console.log("error :", error);
            });
        }
      });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_isactive/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
      this.clearImage();
    },
  },
};
</script>
